<template>
  <div>
    <h1>Starter Page</h1>
    <router-link to="/test">Test</router-link>

    <h1 v-can:admin>This is admin content</h1>
    <h1 v-can:access="acl">ADMIN:WRITE</h1>
  </div>
</template>

<script>
import { dashboardPageCommonAttributes } from '@/mixins/DashboardPageCommonAttributes'

export default {
  ...dashboardPageCommonAttributes,
  components: {
  },
  data: function() {
    return {
      acl: {
        type: 'acl',
        id:'Admin'
      }
    }
  },
  head: {
    title: function () {
      return {
        inner: 'Starter page'
      }
    }
  }
}
</script>
